

export const fullNameRegex = /^[0-9A-Za-z\s]+$/;

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export function validateFullName(inputValue) {
  if (inputValue.trim() === "") {
    return "Name cannot be empty";
  } else if (/^\d/.test(inputValue)) 
  {
    return "Name cannot start with a number";
  } else if (!fullNameRegex.test(inputValue)) {
    return "Name should contain only letters, spaces, and numbers";
  }
  return "";
}

export function validateEmail(inputValue) {
  if (inputValue.trim() === "") {
    return "Email cannot be empty";
  } else if (!emailRegex.test(inputValue)) {
    return "Please enter a valid email";
  }
  return "";
}

const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export function validatePanNumber(inputValue) {
  if(inputValue.length !== 10){
    return "Please enter valid pan";
  }
  else if(!panRegex.test(inputValue)){
    return 'Please enter valid pan';
  }
  else{

  }
}

export function validateMobileNumber(mobileNumber) {
  const mobile = mobileNumber.replace(/[^0-9]/g, "");
  if (mobile.trim() === "") {
    return "Mobile number cannot be empty";
  } else if (mobile.length !== 10) {
    return "Mobile number must have 10 digits";
  }
  else if(mobile.charAt(0) === '0'){
    return "Mobile number cannot start with zero";
  }
  return "";
}

export function validatePincode(inputValue, projectName, listPincodes) {
  const pc = inputValue.replace(/\D/g, "");
  if (projectName === "India Infoline") {
    if (pc.length === 6 && !listPincodes.includes(parseInt(pc))) {
      return "Pincode not found";
    }
    else if (pc === "") {
      return "Pincode cannot be empty";
    }
    else if (pc.length !== 6) {
      return "Pincode must be 6 digits";
    }
    else{
      return "";
    }
    
  } else if (pc === "") {
    return "Pincode cannot be empty";
  } else if (pc.length !== 6) {
    return "Pincode must be 6 digits";
  }
  else{
  return "";
  }
}







export const validatePincodeInput  = (pc, projectName, listPincodes) => {
  pc = pc.replace(/\D/g, "");

  if (projectName === "India Infoline") {
    if (pc.length === 6 && !listPincodes.includes(parseInt(pc))) {
      return "Pincode not found";
    }
  }
  return "";
};
