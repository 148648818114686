import "./App.css";
import React, { useEffect, useState } from "react";
import { importAllImages } from "./Helper";
import { getProjectDetails, isUidExist } from "./Firebase";
import { useSearchParams } from "react-router-dom";
import Spinner from "./Components/Spinner";
import InvalidURL from "./Components/InvalidUrl";
import * as States from "./Components/States";
import LeadSubmissionForm from "./Components/LeadSubmissionForm";

function App() {
  const [state, setState] = useState("");

  const projectElements = {
    projectName: "",
    leadCategory: "",
    bannerUrl: "",
    projectDetails: "",
  };

  const [projectData, setProjectData] = useState(projectElements);

  const [params] = useSearchParams();

  const pCode = params.get("hash");
  const dUid = (params.get("d") !== null ? params.get('d') : params.get('dist'));
  const aUid = (params.get("m") !== null ? params.get('m') : params.get('mem'));;

  // console.log("hash ", pCode, " ", dUid, " : ", aUid);
  const images = importAllImages(
    require.context("./images", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    isUidExist(dUid, (isValidDuid) => {
      if (isValidDuid) {
        isUidExist(aUid, (isValidAuid) => {
          if (isValidAuid) {
            getProjectDetails(pCode, "", function (res) {
              if (res.status === States.SUCCESS) {
                if (res.isOnHold === false) {
                  setProjectData({
                    ...projectData,
                    projectName: res.projectName,
                    leadCategory: res.leadCategory,
                    projectDetails: res.projectDetails,
                    bannerUrl: res.bannerURL,
                  });
                  setState(States.INIT);
                } else {
                  setState(States.ONHOLD);
                }
              } else {
                setState(States.INVALID);
              }
            });
          } else setState(States.INVALID);
        })
      } else setState(States.INVALID);
    })
    // getAgentDetails(dUid, aUid, (res) => {
    //   if (res === "") {
    //     setState(States.INVALID);
    //   } 
      
    //   else {
    //     getProjectDetails(pCode, "", function (res) {
    //       if (res.status === States.SUCCESS) {
    //         if (res.isOnHold === false) {
    //           setProjectData({
    //             ...projectData,
    //             projectName: res.projectName,
    //             leadCategory: res.leadCategory,
    //             projectDetails: res.projectDetails,
    //             bannerUrl: res.bannerURL,
    //           });
    //           setState(States.INIT);
    //         } else {
    //           setState(States.ONHOLD);
    //         }
    //       } else {
    //         setState(States.INVALID);
    //       }
    //     });
    //   }
    // });
  }, []);

  if (state === States.INVALID) {
    return <InvalidURL />;
  }
   else if (state === States.ONHOLD) {
    return (
      <div className="onhold">
        <img
          src={images[projectData.projectName + ".png"]}
          alt=""
          className="projlogo"
        />
        <p className="onholtext">
          Currently we are on hold please visit us after some time!
        </p>
      </div>
    );
  } else if (state === States.INIT) {
    return (
      <div className="product d-flex flex-column">
        <div className="common">
          <LeadSubmissionForm
            projectName={projectData.projectName}
            dUid={dUid}
            aUid={aUid}
            projectData={projectData}
          />
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default App;