import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
// import Lead from './Components/Lead';
import App from './App';
import Form from './Components/Form'
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import Track from './Components/Track';
import EcomForm from './Components/EcomForm';
import Form02 from './Components/Form02';
import Form03 from './Components/Form03';
const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/gt" element= { <Form02/>} />
      <Route path="/gr" element= { <Form/>} />
      <Route path="/gr/mf" element= { <Form/>} />
      <Route path="/ecom" element= { <EcomForm/>} />
      <Route path="/trk" element= { <Track/>} />
      <Route path="/mf" element= { <Form03/>} />
      {/* <Route path="/Lead" element={<Lead />} /> */}
    </Routes>
  </Router>
);

reportWebVitals();
