import React, { useState } from "react";
import { PincodesMap } from "./Pincodes";
import "../css/landingpage.css";
import ProjectDetails from "./ProjectDetails";
import { postLead , checkPincode } from "../Firebase";
import PincodeLoader from "./PincodeLoader";
import Error from "./Error";
import * as States from "./States";
import * as Validation from "./Validations";
import Spinner from "./Spinner";

function MFPan(props) {
  const dUid = props.dUid;
  const aUid = props.aUid;
  const projectName = props.projectName;
  const leadCategory = props.projectData.leadCategory;
  const details = props.projectData.projectDetails;
  const url = props.projectData.bannerUrl;

  var listPincodes = PincodesMap.get(projectName) || [];

  const [isLoadingPincode, setIsLoadingPincode] = useState(false);
  const [verifyPincode , setVerifyPincode] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [showProceedButton, setShowProceedButton] = useState(false);

  const [state, setState] = useState(States.INIT);

  const customerData = { name: "", pan: "", mobile: "", email: "", pincode: "" };
  const [customerDetails, setCustomerDetails] = useState(customerData);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    pan: "",
    mobile: "",
    email: "",
    pincode: "",
  });

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails({ ...customerDetails, [name]: value });

    if (value !== "") {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const onInputPAN = (e) => {
    const { name, value } = e.target;

    setCustomerDetails({ ...customerDetails, [name]: value.toUpperCase() });

    if(value.length === 10){
      if (panRegex.test(value.toUpperCase())) {
        setFormErrors({...formErrors, [name] : ''});
      }
      else{
          setFormErrors({...formErrors, [name] : 'PAN Number is not valid'});
        }
    }

};

  const fetchPincodeData = (pincode) => {
    const name = 'pincode';
    setIsLoadingPincode(true);
    checkPincode(pincode , (res) => {
      if(res.valid){
        setFormErrors({ ...formErrors, [name]: "" });
        setIsLoadingPincode(false);
        setVerifyPincode(true);
      }
      else{
        setFormErrors({ ...formErrors, [name]: States.INVALID_PINCODE });
        setIsLoadingPincode(false);
      }
    })
    
  };

  const handlePincodeChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails({ ...customerDetails, [name]: value });
    
    if ( 
      leadCategory === "Demat Account" ||
      leadCategory === "Savings Account" ||
      leadCategory === "UPI" ||
      leadCategory === "Business Account" ||
      leadCategory === "Current Account"
    ) {
      if (/^\d{6}$/.test(value)) 
      {
        fetchPincodeData(value);
      } else 
      {
        setFormErrors({ ...formErrors, [name]: "" });
        setVerifyPincode(false);
      }
    } else {
      if (/^\d{6}$/.test(value)) {
        setVerifyPincode(true);
        setFormErrors({ ...formErrors, [name]: "" });
      }
      else{
        setVerifyPincode(false);
        setFormErrors({ ...formErrors, [name]: "" });
      }
     

    }
  };

  function setPincodeVal(val) {
    setCustomerDetails({ ...customerDetails, pinocde: val.toString() });
  }
  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    setIsButtonDisabled(!e.target.checked);
  };

  const handleContinueClick = () => {
    setIsFormSubmitted(true);
    const fullNameValidation = Validation.validateFullName(
      customerDetails.name
    );
    const panNumberValidation = Validation.validatePanNumber(customerDetails.pan);
    const emailValidation = Validation.validateEmail(customerDetails.email);
    const pincodeValidation = Validation.validatePincode(
      customerDetails.pincode,
      projectName,
      listPincodes
    );

    const mobileNumberValidation = Validation.validateMobileNumber(
      customerDetails.mobile
    );

    setFormErrors({
      name: fullNameValidation,
      pan: panNumberValidation,
      mobile: mobileNumberValidation,
      email: emailValidation,
      pincode: pincodeValidation,
    });

    if (
      !fullNameValidation &&
      !emailValidation &&
      !pincodeValidation &&
      !mobileNumberValidation &&
      !panNumberValidation &&
      isCheckboxChecked
    ) {
      const lead = {
        DistId: dUid,
        agentId: aUid,
        customerName: customerDetails.name.trim(),
        customerMobile: customerDetails.mobile,
        customerEmail: customerDetails.email.trim(),
        customerPincode: customerDetails.pincode,
        customerPan: customerDetails.pan,
        projectName: projectName,
        leadCategory: leadCategory,
      };

      setState(States.WAITING);
      postLead(lead, function (res) {
        if (res.status === States.SUCCESS) {
          if (res.submitted === true && res.link !== null) {
            window.open(res.link, "_self");
          } else if (res.isLeadExist === true) {
          }
        } else {
          setState(States.ERROR);
        }
      });
    }
  };

  const handleViewDetails = () => {
    setShowDetails(true);
    setShowProceedButton(true);
  };

  const handlehideDetails = () => {
    setShowDetails(false);
    setShowProceedButton(false);
    setFormErrors({ name: "", mobile: "", email: "", pincode: "" });
  };

  if (state === States.INIT) {
    return (
      <>
        <section className="h-100 h-custom">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-lg-8 col-xl-7">
                <div
                  className="card rounded-3"
                  style={isBannerLoaded ? {} : { display: "none" }}
                >
                  <img
                    src={url}
                    className="w-100 banner"
                    alt="EarnMoney"
                    onLoad={() => setIsBannerLoaded(true)}
                  />

                  <div
                    className="tag"
                    style={isBannerLoaded ? {} : { display: "none" }}
                  >
                    {showDetails ? (
                      <p></p>
                    ) : (
                      <p onClick={handleViewDetails}>View Features</p>
                    )}
                  </div>

                  <div
                    className="card-body p-md-4"
                    style={isBannerLoaded ? {} : { display: "none" }}
                  >
                    {!showDetails && (
                      <form
                        action=""
                        method="post"
                        id="leadform"
                        className="px-md-2"
                      >
                        <div className="registration">
                          <div className="form-outline mb-4" id="div1">
                            <label
                              className="form-label"
                              htmlFor="fullname"
                              id="name"
                            >
                              Name
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fullname"
                              maxLength="25"
                              name="name"
                              value={customerDetails.name}
                              onChange={onInputChange}
                            />
                            {isFormSubmitted && formErrors.name && (
                              <div className="error-message" id="namingerror">
                                <span className="error-star">*</span>{" "}
                                {formErrors.name}
                              </div>
                            )}
                          </div>

                          <div className="form-outline mb-4" id="div1">
                            <label
                              className="form-label"
                              htmlFor="pan"
                              id="pan"
                            >
                              PAN
                            </label>
                            <br />
                            <input
                              type="text"
                              id="pan"
                              maxLength="10"
                              name="pan"
                              value={customerDetails.pan}
                              onChange={onInputPAN}
                            />
                            {!isFormSubmitted && formErrors.pan && (
                              <div className="error-message" id="panerror">
                                <span className="error-star">*</span>{" "}
                                {formErrors.pan}
                              </div>
                            )}
                            {isFormSubmitted && formErrors.pan && (
                              <div className="error-message" id="panerror">
                                <span className="error-star">*</span>{" "}
                                {formErrors.pan}
                              </div>
                            )}
                          </div>

                          <div className="form-outline mb-4" id="div2">
                            <label
                              className="form-label"
                              htmlFor="mobileNumber"
                              id="mobile"
                            >
                              Mobile
                            </label>
                            <br />
                            <div className="input-name">
                              <div className="countrycode">
                                <input
                                  type="tel"
                                  id="code"
                                  placeholder="+91"
                                  disabled
                                />
                              </div>
                              <input
                                type="tel"
                                id="mobileNumber"
                                name="mobile"
                                required
                                maxLength="10"
                                minLength="10"
                                pattern="[0-9]{10}"
                                value={customerDetails.mobile}
                                onChange={onInputChange}
                              />
                            </div>
                            {isFormSubmitted && formErrors.mobile && (
                              <div className="error-message">
                                <span className="error-star">*</span>{" "}
                                {formErrors.mobile}
                              </div>
                            )}
                          </div>

                          <div className="form-outline mb-4" id="div3">
                            <label className="form-label" htmlFor="email">
                              Email
                            </label>
                            <br />
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={customerDetails.email}
                              onChange={onInputChange}
                            />
                            {isFormSubmitted && formErrors.email && (
                              <div className="error-message">
                                <span className="error-star">*</span>{" "}
                                {formErrors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-outline mb-4" id="div4">
                            <div className="fiels">
                              <label className="form-label" htmlFor="pincode">
                                Pincode
                              </label>
                              <br />
                              <div className="pincode-input-container">
                              <input
                                type="tel"
                                id="pincode"
                                name="pincode"
                                value={customerDetails.pincode}
                                maxLength="6"
                                onChange={handlePincodeChange}
                              />
                                 {isLoadingPincode && (
                                <div className="pincode-spinner">
                                  <PincodeLoader />
                                </div>
                              )}
                              </div>
                              {isFormSubmitted && formErrors.pincode && (
                                <div className="error-message">
                                  <span className="error-star">*</span>
                                  {formErrors.pincode}
                                </div>
                              )}
                              {!isFormSubmitted && formErrors.pincode && (
                                <div className="error-message">
                                  <span className="error-star">*</span>
                                  {formErrors.pincode}
                                </div>
                              )}
                            </div>
                            <div className="dropdown-flex w-100">
                              {listPincodes
                                .filter((item) => {
                                  return (
                                    parseInt(customerDetails.pincode) &&
                                    item
                                      .toString()
                                      .startsWith(customerDetails.pincode) &&
                                    parseInt(customerDetails.pincode) !== item
                                  );
                                })
                                .map((item) => (
                                  <div key={item}>
                                    <div
                                      onClick={() => setPincodeVal(item)}
                                      className="dropdown-row"
                                    >
                                      {item}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <button
                            type="button"
                            className={`btn btn-lg mb-1 ${
                              isButtonDisabled ? "disabled" : ""
                            }`}
                            onClick={handleContinueClick}
                            id="start"
                            disabled={!verifyPincode}
                            style={{
                              backgroundColor: isButtonDisabled ? "grey" : "",
                              cursor: isButtonDisabled
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            Start
                          </button>
                        </div>
                        <div className="checkbox-container">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                            onChange={handleCheckboxChange}
                            checked={isCheckboxChecked}
                          />
                          <label className="form-check-label" htmlFor="check">
                            I authorize owner of customerleads.co.in to securely store & use my data to
                            call/SMS/WhatsApp/email me about its products & have
                            accepted the terms of the privacy policy.
                          </label>
                        </div>
                      </form>
                    )}
                    {showDetails && (
                      <ProjectDetails projectDetails={details}></ProjectDetails>
                    )}
                    {showProceedButton && (
                      <button
                        type="button"
                        className="btn  btn-lg mb-1"
                        onClick={handlehideDetails}
                        id="proceed"
                      >
                        Proceed
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else if (state === States.WAITING) {
    return <Spinner />;
  } else if (state === States.ERROR) {
    return <Error />;
  }
}

export default MFPan;
